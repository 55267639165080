export const etAdminMenu = [
  // {
  //   key: 'dashboard',
  //   title: 'Dashboard',
  //   route: '/main/dashboard',
  //   icon: 'dashboard',
  //   children: [],
  // },
  {
    key: 'topic',
    title: 'Topic',
    route: '/main/topic',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'blog',
    title: 'Blog',
    route: '/main/blogs',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'podcast',
    title: 'Podcast',
    route: '/main/podcast',
    icon: 'question-circle',
    children: [],
  },

  {
    key: 'press',
    title: 'Press',
    route: '/main/press',
    icon: 'question-circle',
    children: [],
  },
  {
    key: 'speaking',
    title: 'Speaking',
    route: '/main/speaking',
    icon: 'question-circle',
  },
  {
    key: 'Resume',
    title: 'Resume',
    route: '/main/resume',
    icon: 'question-circle',
  },
  {
    key: 'newsletter',
    title: 'Newsletter',
    route: '/main/newsletter',
    icon: 'user',
    children: [],
  }

];
