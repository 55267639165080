import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent {
  isCollapsed = false;
  constructor() {}

  onCollapseChange(value: any): void {
    this.isCollapsed = value;
  }
}
