<nz-header>
  <div class="app-header">
    <div nz-row>
      <div nz-col nzSpan="18">
        <div class="logo">
          <h2>Dr. sid</h2>
        </div>
      </div>
      <div nz-col nzSpan="6" class="rightPart">
        <!-- <div>
          <nz-badge [nzCount]="5">
            <button nz-button nzType="link" (click)="openNotificationComponent()" >
              <i nz-icon nzType="bell" nzTheme="outline" class="bell-icon" ></i>
            </button>
          </nz-badge>
          <ng-template #Content> This is content</ng-template>
        </div>
         -->
        <div>
          <nz-avatar
            nzIcon="user"
            class="avatar"
            nzSrc=""
            nz-button
            nz-dropdown
            [nzDropdownMenu]="menu"
            [nzPlacement]="'bottomRight'"
          ></nz-avatar>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <a [routerLink]="['/main/profile']"
                  ><i nz-icon nzType="user" nzTheme="outline"></i>My Profile</a
                >
              </li>
              <li nz-menu-item (click)="logout()">
                <a><i nz-icon nzType="logout" nzTheme="outline"></i>Logout</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </div>
  </div>
</nz-header>
