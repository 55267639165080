<nz-layout class="app-layout">
  <app-header (collapsed)="onCollapseChange($event)"></app-header>  
  <nz-layout>
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" nzBreakpoint="md" [nzTrigger]="null" nzWidth="280px">
      <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
    </nz-sider>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
      <nz-footer>
        <app-footer></app-footer>           
      </nz-footer>
    </nz-content>
    
  </nz-layout>
</nz-layout>
