import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor() {}
  get apiUrl(): string {
    return 'https://api.siddiqanwar.com';
    // return 'http://localhost:5001';
  }

  get mediaBaseUrl(): string {
    return environment.production ? 'https://sid-web-storage.s3.ap-south-1.amazonaws.com/' : 'https://sid-web-storage.s3.ap-south-1.amazonaws.com/';
  }
}
